import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import consulting from "../images/consulting.svg";

import emoji from "../images/emoji.png";
import circle_el from "../images/circle_el.svg";

import "../styles/reusablefooter.css";

export default function ReusableFooter({ text }) {
  return (
    <Flex
      w="100%"
      alignItems="center"
      h="500px"
      flexWrap="wrap"
      mt="100px"
      justifyContent="center"
    >
      <Box
        w={["100%", "100%", "50%", "40%", "400px"]}
        h={["250px", "250px", "350px", "500px", "500px"]}
        mx={["0px", "0px", "50px", "100px", "200px"]}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        // className="pattern-dots-lg"
      >
        <img src={consulting} alt="consulting.svg" className="svg svg1" />
      </Box>
      <Flex
        p="20px"
        w={["100%", "100%", "36%", "35 %", "35%"]}
        justifyContent="center"
        flexDirection="column"
        alignItems="start"
      >
        <Text
          className="requesth1"
          fontSize={["50px", "60px", "60px", "60px", "65px"]}
        >
          START NOW!
        </Text>
        <Text
          className="requesth2"
          fontSize={["20px", "30px", "30px", "30px", "30px"]}
        >
          Request a callback <br /> <br />
          today!
        </Text>
        <Box justifyContent="center" flexDirection="column" alignItems="center">
          <button
            className="bizbut indexBizbut"
            onClick={() => {
              window.location.assign("/contact");
            }}
          >
            BUSINESS ENQUIRY
          </button>
        </Box>
      </Flex>
      {/* {/* <Box>
          <img src={rect} alt="svg" className="circle_el" />
        </Box> */}
    </Flex>
  );
}
